<template>
  <a-select v-model="type" class="select">
    <a-select-option v-for="(item) in types"
                     :key="item.id"
                     :value="item.id">
      {{ item.name }}
    </a-select-option>
  </a-select>
</template>

<script>
const types = [
  { id: 'year', name: '年' },
  { id: 'quarter', name: '季' },
  { id: 'month', name: '月' },
  { id: 'week', name: '周' },
];

export default {
  name: 'SelectDateType',
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    value: {
      type: String,
      default: undefined,
      validator: (type) => types.some((i) => i.id === type),
    },
    disabledTypes: { type: String, default: '' },
  },
  watch: {
    type(val) {
      if (val !== this.value) {
        this.$emit('change', this.type);
      }
    },
    value: {
      immediate: true,
      handler(val) {
        this.type = val || undefined;
      },
    },
    disabledTypes: {
      immediate: true,
      handler(val) {
        this.types = types.filter((i) => !val?.split(',')?.includes(i.id));
      },
    },
  },
  data() {
    return {
      type: undefined,
      types,
    };
  },
};
</script>

<style scoped lang="scss">
@import "../../scss/select.scss";
.select{
  height: 32px;
}
</style>
