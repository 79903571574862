<template>
  <div ref="chartRef" class="chart">
  </div>
</template>
<script>
import * as echarts from 'echarts';
import {
  lineShadow,
  baseColor,
  setDateZoom,
  setTitle,
  saveImage,
  defaultLegend,
} from '../../assets/EChartsConfig';

export default {
  name: 'SingleGridLineChart',
  props: {
    chartData: { type: Object, default: () => ({}) },
  },
  watch: {
    chartData(data) {
      this.updateData(data);
    },
  },
  data() {
    return {
      chart: null,
      isFocus: false,
    };
  },
  mounted() {
    this.initChart();
  },
  methods: {
    handleMove(params) {
      if (params.componentType === 'xAxis') {
        const elementDiv = document.querySelector('.extension');
        // 设置悬浮文本的位置以及样式
        const elementStyle = 'position: absolute;z-index: 99999;color: #fff;font-size: 12px;padding: 5px;display: inline;border-radius: 4px;background-color: #303133;box-shadow: rgba(0, 0, 0, 0.3) 2px 2px 8px';
        elementDiv.style.cssText = elementStyle;
        elementDiv.innerHTML = params.value;
        document.querySelector('html').onmousemove = function (event) {
          // eslint-disable-next-line no-shadow
          const elementDiv = document.querySelector('.extension');
          const xx = event.pageX - 10;
          const yy = event.pageY + 15;

          elementDiv.style.top = `${yy}px`;
          elementDiv.style.left = `${xx}px`;
        };
      }
    },
    handleOut(params) {
      // 注意这里，我是以X轴显示内容过长为例，如果是y轴的话，需要改为yAxis
      if (params.componentType === 'xAxis') {
        const elementDiv = document.querySelector('.extension');

        elementDiv.style.cssText = 'display:none';
      }
    },
    initChart() {
      const ref = this.$refs.chartRef;
      if (!ref) {
        return;
      }

      this.chart = echarts.init(ref);
      this.updateData(this.chartData);
      this.chart.on('mousemove', 'xAxis', this.handleMove);
      this.chart.on('mouseout', 'xAxis', this.handleOut);
    },

    setSeriesItem(option) {
      // eslint-disable-next-line array-callback-return
      option.series.map((i, idx) => {
        i.symbol = 'circle';
        i.symbolSize = 14;
        i.itemStyle = {
          normal: {
            color: option?.color?.[idx],
            borderWidth: 2,
            borderType: 'solid',
            borderColor: '#FFFFFF',
            label: {
              show: true, // 开启显示
              position: 'top', // 在上方显示
              textStyle: {
                // 数值样式
                'font-family': 'DINCondensed-Bold, DINCondensed',
                color: option?.color?.[idx],
                fontSize: 12,
                'font-weight': 'bold',
                'line-height': '14px',
              },
              formatter(value) {
                const { data } = value;
                let result = data;

                if (data > 1000) {
                  result = `${
                    data > 1000 ? `${(data / 1000).toFixed(1)}k` : data
                  }`;
                }
                console.log(data, result);
                return result;
              },
            },
            lineStyle: {
              ...lineShadow,
              shadowBlur: 5,
              shadowOffsetY: 15,
            },
          },
        };
        i.triggerLineEvent = true;
        i.label = {
          show: true,
          formatter: '{c}',
        };
        i.hoverAnimation = true;
      });
    },

    updateData(data) {
      if (!this.chart) {
        return;
      }
      if (this.chart?.showLoading) {
        this.chart.showLoading();
      }

      const {
        date = [],
        series = [],
        color = [...baseColor],
        zoomX = false,
        zoomY = false,
        title = '',
        yAxisName = '',
        yAxisLabelFormatter = '{value}',
      } = data || {};

      const option = {
        color,
        tooltip: {
          trigger: 'axis',
          formatter(params) {
            if (!params?.length) {
              return;
            }
            const xAxisLabel = params[0].axisValueLabel;

            const items = params
              .map((i) => (
                `${'<span style="display: inline-block; '
                  + 'width: 12px; '
                  + 'height: 12px; '
                  + 'border-radius: 6px; '
                  + 'margin-right: 10px; '
                  + 'background-color: '}${
                  i.color
                };"></span>`
                  + `<span style="display: inline-block; min-width: 100px;margin-right:10px">${
                    i.seriesName
                  }</span>`
                  + `<span style="display: inline-block; font-weight: 600;">${
                    i.value
                  }</span>`
                  + '<br/>'
              ))
              .join('');
            // eslint-disable-next-line consistent-return
            return `${xAxisLabel}<br/>${items}`;
          },
          axisPointer: {
            type: 'none',
          },
        },
        grid: {
          left: '50',
          right: '30',
          bottom: '130',
        },
        legend: {
          ...defaultLegend(),
          show: false,
          data: series.map((i) => i.name),
        },
        xAxis: [
          {
            type: 'category',
            boundaryGap: false,
            axisTick: {
              show: false,
              alignWithLabel: true,
            },
            axisLine: {
              show: false,
            },
            triggerEvent: true,
            axisLabel: {
              rotate: 60,
              margin: 20,

              // formatter: function (value) {
              //   if (value.length > 4) {
              //     return `${value.length > 4 ? value.slice(0, 4) : value}...`;
              //   }
              //   return value;
              // }
            },
            splitLine: {
              show: true,
              onZero: true,
              lineStyle: {
                width: 1,
                type: 'dashed',
                color: ['#D4D4D4'],
              },
            },
            data: date,
          },
        ],
        yAxis: [
          {
            type: 'value',
            name: yAxisName || '',
            min: 0,
            position: 'left',
            axisLine: {
              show: false,
            },
            axisLabel: {
              formatter: yAxisLabelFormatter,
              margin: 20,
            },
            splitLine: {
              show: true,
              onZero: true,
              lineStyle: {
                width: 1,
                type: 'dashed',
                color: ['#D4D4D4'],
              },
            },
          },
        ],
        dataZoom: [],
        series,
      };

      setTitle(option, title);
      this.setSeriesItem(option);
      setDateZoom(option, zoomX, zoomY, 7, true);
      this.chart.setOption(option, true);
      this.chart.hideLoading();
    },

    saveImage(fileName) {
      saveImage(this.chart, fileName);
    },
    showLoading() {
      this.chart.showLoading();
    },
    hideLoading() {
      this.chart.hideLoading();
    },
    computedPosition(length, xArraylength) {
      if (xArraylength >= 10) {
        // eslint-disable-next-line no-return-assign
        return length <= 10
          ? (this.end = 35)
          : (this.end = 100 - Math.floor((35 / length) * 100));
      }
      return 100; // 小于十条数据显示全部
    },
  },
};
</script>

<style scoped lang="scss">
.chart {
  width: 330px;
  height: 480px;
}
</style>
