<template>
  <a-button class="btn" @click="$emit('click')" :loading="loading" :class="{'btn-loading':loading}">
    <img :src="imgUrl" alt="" class="btn-img" />
    <span>导出数据</span>
  </a-button>
</template>

<script>
import { ossBaseUrl } from '@/Config';

export default {
  name: 'ButtonExportData',
  props: {
    loading: {
      default: () => false
    }
  },
  data() {
    return {
      imgUrl: `${ossBaseUrl}dataCenter/buttons/export_data.png`
    };
  }
};
</script>

<style scoped lang="scss">
.btn {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 117px;
  height: 35px;
  border: 1px solid #334fa0;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 400;
  color: #334fa0;
  font-family: PingFangSC-Medium, PingFang SC, sans-serif;
  background: #ffffff;
      padding: 0 !important;
}

.btn-img {
  width: 19px;
  height: 20px;
  margin-right: 9px;
}
.btn-loading{
      cursor: wait;
    pointer-events: auto !important;
}
::v-deep {
  .anticon-loading {
    overflow: hidden;
    width: 0;
    height: 0;
    margin: 0 !important;
    padding: 0 !important;
  }
  .ant-btn-loading {
    padding-left: 0 !important;
    margin: 0 !important;
    padding: 0 !important;
  }


  & > .anticon + span,
  & > span + .anticon {
    margin-left: 0 !important;
  }
}
</style>
