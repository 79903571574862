<template>
  <a-button class="btn" @click="clicked">
    <img :src="imgUrl" alt="" class="btn-img">
    <span>下载图片</span>
  </a-button>
</template>

<script>
import {
  ossBaseUrl,
} from '@/Config';

export default {
  name: 'ButtonDownload',
  data() {
    return {
      imgUrl: `${ossBaseUrl}dataCenter/buttons/download.png`,
      combo: false,
    };
  },
  methods: {
    clicked() {
      if (this.combo || this.loading) {
        return;
      }
      this.combo = true;
      setTimeout(() => {
        this.combo = false;
      }, 1200);

      this.$emit('click');
    },
  },
};
</script>

<style scoped lang="scss">
.btn {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 117px;
  height: 37px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 500;
  color: #FFFFFF;
  font-family: PingFangSC-Medium, PingFang SC, sans-serif;
  background: linear-gradient(90deg, #334FA0 0%, #3C85B8 100%);
}

.btn-img {
  width: 19px;
  height: 20px;
  margin-right: 9px;
}
</style>
