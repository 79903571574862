<template>
  <a-select
    v-model="ids"
    :default-value="ids"
    mode="multiple"
    :allow-clear="true"
    :show-arrow="true"
    :placeholder="placeholder"
    class="select"
  >
    <a-select-option v-for="item in options" :key="item.id" :value="item.id">
      {{ item.name }}
    </a-select-option>
  </a-select>
</template>

<script>
export default {
  name: 'SelectMultiple',
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    value: { type: String, default: '' },
    options: { type: Array, default: () => [] },
    placeholder: { type: String, default: '请选择' }
  },
  watch: {
    value: {
      immediate: true,
      handler(val) {
        this.ids = val
          ? val
              .split(',')
              .map((i) => +i)
              .filter((i) => !!i)
          : [];
      }
    },
    ids: {
      immediate: true,
      handler(val, old) {
        this.ids = this.ids.length > 0 ? this.ids : [3];
        if (
          val &&
          val?.length === old?.length &&
          val.every((i, idx) => i === old[idx])
        ) {
          return;
        }
        this.$emit('change', val?.join(',') || '');
        this.$emit(
          'changed',
          this.options.length > 0
            ? this.options
                .filter((i) => val?.includes(i.id))
                .map((i) => ({ ...i }))
            : ['3']
        );
      }
    }
  },
  data() {
    return {
      ids: []
    };
  }
};
</script>

<style scoped lang="scss">
@import '../../scss/select.scss';

.select {
  width: 275px;

  ::v-deep {
    .ant-select-selection {
      width: 275px;
    }

    .ant-select-selection__rendered {
      width: 243px;

      & > ul {
        padding-left: 8px;
      }

      &::after {
        display: none;
      }
    }

    .ant-select-selection__choice {
      padding-left: 6px;
      padding-right: 6px;
    }

    .ant-select-selection__placeholder {
      margin-left: 9px;
      font-size: 12px;
      color: #6677a5;
    }

    .ant-select-search {
      &,
      * {
        width: 0;
        height: 0;
        margin: 0;
        padding: 0;
      }
    }

    .ant-select-selection__choice__remove {
      display: none;
    }

    .ant-select-selection__clear {
      right: 8px;
    }
  }
}
</style>
