import moment from 'moment';

const mixin = {
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    value: { type: Array, default: () => [undefined, undefined] },
  },
  computed: {
    date() {
      return this.dateRange?.[0] || undefined;
    },
  },
  watch: {
    dateRange: {
      immediate: true,
      deep: true,
      handler(val, old) {
        if (val && val?.length === 2 && old?.length === 2 && val.every(
          (i, idx) => i?.format('YYYY-MM-DD') === old?.[idx]?.format('YYYY-MM-DD'),
        )) {
          return;
        }
        this.$emit('change', [
          this.dateRange?.[0]?.format('YYYY-MM-DD') || undefined,
          this.dateRange?.[1]?.format('YYYY-MM-DD') || undefined,
        ]);
        this.$emit('changed', {
          type: this.type,
          date: this.dateRange?.[0]?.format(this.format),
        });
      },
    },
    value: {
      immediate: true,
      handler(val, old) {
        if (val && val?.length === 2 && old?.length === 2 && val.every(
          (i, idx) => i === old?.[idx],
        )) {
          return;
        }
        this.dateRange = [
          val?.[0] ? moment(val[0], 'YYYY-MM-DD') : undefined,
          val?.[1] ? moment(val[1], 'YYYY-MM-DD') : undefined,
        ];
      },
    },
  },
  data() {
    return {
      originalDate: '',
      dateRange: [undefined, undefined],
      open: false,

      type: '',
      mode: 'year',
      format: '',
    };
  },
  methods: {
    onChange(date) {
      this.dateRange = [date?.clone().startOf(this.type), date?.clone().endOf(this.type)];
    },
    openChange(status) {
      this.open = !!status;
    },
    panelChange(date, mode) {
      this.mode = mode;
      this.dateRange = [date?.clone().startOf(this.type), date?.clone().endOf(this.type)];

      if (!mode) {
        this.mode = 'year';
        this.open = false;
      }
    },
  },
};

export default mixin;
