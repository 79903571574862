<template>
  <div class="range-quarter" :class="{'single': single}" @click.stop="showPanel">
    <div class="select-quarter">
      <div v-if="!startDate" class="placeholder">
        <span v-if="single">请选择季度</span>
        <span v-else>开始季度</span>
      </div>
      <div v-else>{{ startDate }}季度</div>
    </div>
    <span v-if="!single" class="separator">至</span>
    <div v-if="!single" class="select-quarter">
      <div v-if="!endDate" class="placeholder">结束季度</div>
      <div v-else>{{ endDate }}季度</div>
    </div>
    <div v-if="showSuffix" class="suffix">
      <a-icon type="down" class="icon"/>
    </div>

    <div v-if="open" class="select-panel ant-calendar" :class="{'single': single}" @click.stop="">
      <div class="start">
        <div class="header ant-calendar-month-panel-header">
          <div class="prev" @click="prev('start')"></div>
          <span class="year">{{ startYear }}</span>
          <div class="next"
               :class="{'disabled': startYearNextDisabled && !single}"
               @click="next('start')"/>
        </div>
        <div class="content">
          <div v-for="(item) in quarters"
               :key="item.id"
               class="quarter"
               :class="{'active': startQuarter === item.id, 'disabled': startQuarterDisabled(item.id)}"
               @click="selectStartQuarter(item)">
            {{ item.name }}
          </div>
        </div>
      </div>

      <div v-if="!single" class="end">
        <div class="header ant-calendar-month-panel-header">
          <div class="prev" :class="{'disabled': endYearPrevDisabled}" @click="prev('end')"></div>
          <span class="year">{{ endYear }}</span>
          <div class="next" @click="next('end')"></div>
        </div>
        <div class="content">
          <div v-for="(item) in quarters"
               :key="item.id"
               class="quarter"
               :class="{'active': endQuarter === item.id, 'disabled': endQuarterDisabled(item.id)}"
               @click="selectEndQuarter(item)">
            {{ item.name }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  name: 'SelectRangeQuarter',
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    value: { type: Array, default: () => [undefined, undefined] },
    single: { type: Boolean, default: false },
    showSuffix: { type: Boolean, default: false },
  },
  computed: {
    startDate() {
      return (this.startYear && this.startQuarter) ? `${this.startYear}-${this.startQuarter}` : '';
    },
    endDate() {
      return (this.endYear && this.endQuarter) ? `${this.endYear}-${this.endQuarter}` : '';
    },
    startYearNextDisabled() {
      return +this.startYear >= +this.endYear;
    },
    endYearPrevDisabled() {
      return +this.endYear <= +this.startYear;
    },
    startQuarterDisabled() {
      return (id) => this.startYearNextDisabled && id >= this.endQuarter;
    },
    endQuarterDisabled() {
      return (id) => this.endYearPrevDisabled && id <= this.startQuarter;
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(val) {
        if (val[0]) {
          const startYear = moment(val[0], 'YYYY-Q').format('YYYY');
          const startQuarter = moment(val[0], 'YYYY-Q').format('Q');

          if (startYear !== this.startYear) {
            this.startYear = startYear;
          }
          if (startQuarter !== this.startQuarter) {
            this.startQuarter = +startQuarter;
          }
        }

        if (val[1]) {
          const endYear = moment(val[1], 'YYYY-Q').format('YYYY');
          const endQuarter = moment(val[1], 'YYYY-Q').format('Q');

          if (endYear !== this.endYear) {
            this.endYear = endYear;
          }
          if (endQuarter !== this.endQuarter) {
            this.endQuarter = +endQuarter;
          }
        }
      },
    },
  },
  mounted() {
    document.addEventListener('click', this.hidePanel, false);
  },
  beforeDestroy() {
    document.removeEventListener('click', this.hidePanel);
  },
  data() {
    return {
      startYear: moment().format('YYYY'),
      endYear: moment().format('YYYY'),
      startQuarter: undefined,
      endQuarter: undefined,

      quarters: [
        { id: 1, name: '第一季度' },
        { id: 2, name: '第二季度' },
        { id: 3, name: '第三季度' },
        { id: 4, name: '第四季度' },
      ],

      open: false,
    };
  },
  methods: {
    showPanel() {
      this.open = true;
    },
    hidePanel() {
      this.open = false;
    },
    prev(type) {
      switch (type) {
        case 'start':
          this.startYear = moment(this.startYear, 'YYYY').subtract(1, 'years').format('YYYY');
          // this.startQuarter = undefined;
          break;
        case 'end':
          this.endYear = moment(this.endYear, 'YYYY').subtract(1, 'years').format('YYYY');
          // this.endQuarter = undefined;
          break;
        default:
      }

      this.emit();
    },
    next(type) {
      switch (type) {
        case 'start':
          if (moment(this.startYear, 'YYYY').isBefore(moment(this.endYear, 'YYYY'))
            || this.single) {
            this.startYear = moment(this.startYear, 'YYYY').add(1, 'years').format('YYYY');
            // this.startQuarter = undefined;
          }
          break;
        case 'end':
          this.endYear = moment(this.endYear, 'YYYY').add(1, 'years').format('YYYY');
          // this.endQuarter = undefined;
          break;
        default:
      }

      this.emit();
    },

    selectStartQuarter(item) {
      this.startQuarter = item.id;
      this.emit();

      if (this.single) {
        this.hidePanel();
      }
    },
    selectEndQuarter(item) {
      this.endQuarter = item.id;
      this.emit();

      if (this.startQuarter) {
        this.hidePanel();
      }
    },

    emit() {
      const dates = [undefined, undefined];
      if (this.startYear && this.startQuarter) {
        dates[0] = `${this.startYear}-${this.startQuarter}`;
      }
      if (this.endYear && this.endQuarter) {
        dates[1] = `${this.endYear}-${this.endQuarter}`;
      }
      this.$emit('change', dates);
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../scss/base.scss";

.range-quarter {
  position: relative;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  width: 200px;
  height: 31px;
  padding: 1px 16px 0 9px;
  border: $border;
  cursor: text;

  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC, sans-serif;
  font-weight: 400;
  color: #6677A5;

  &.single {
    width: 141px;

    .select-quarter {
      justify-content: flex-start;
      padding-bottom: 1px;
    }
  }
}

.suffix {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 100%;
  border-left: $border;

  .icon {
    font-size: 11px;
    color: #8FA7C3;
    transform: scaleY(0.85);
  }
}

.separator {
  color: rgba(0, 0, 0, 0.45);
}

.select-quarter {
  flex: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 140px;
  height: 29px;
  border: none;
}

.select-panel {
  overflow: hidden;
  display: flex;
  position: absolute;
  top: -2px;
  left: 1px;
  z-index: 99;
  width: 275px;
  min-height: 40px;

  &.single {
    width: 140px;

    .start {
      margin-right: 0;
    }
  }

  .start, .end {
    width: 135px;
  }

  .start {
    margin-right: 10px;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .year {
    color: rgba(0, 0, 0, 0.85);
    font-weight: 500;
  }

  .prev, .next {
    padding: 0 12px;
    cursor: pointer;

    &.disabled {
      cursor: not-allowed;
    }

    &:not(.disabled):hover {
      &:before, &:after {
        border-color: rgba(0, 0, 0, 0.65);
      }
    }

    &:before, &:after {
      position: relative;
      top: -1px;
      display: inline-block;
      width: 8px;
      height: 8px;
      vertical-align: middle;
      border: 0 solid #AAAAAA;
      border-width: 1.5px 0 0 1.5px;
      border-radius: 1px;
      transform: rotate(-45deg) scale(0.8);
      transition: all 0.3s;
      content: '';
    }

    &:after {
      transform: rotate(-45deg) scale(0.8) translate(-3px, -3px);
    }
  }

  .next {
    &:before, &:after {
      transform: rotate(135deg) scale(0.8);
    }

    &:before {
      transform: rotate(135deg) scale(0.8) translate(-3px, -3px);
    }
  }

  .quarter {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    position: relative;
    height: 34px;
    line-height: 34px;
    margin-bottom: 3px;
    padding: 0 20px;
    font-size: 14px;
    text-align: center;
    color: #606266;
    cursor: pointer;

    &:not(.disabled, .active):hover {
      background-color: #F5F7FA;
    }

    &.active {
      color: #FFFFFF;
      background-color: $mainColor;
    }

    &.disabled {
      color: rgba(0, 0, 0, 0.25);
      background: #f5f5f5;
      cursor: not-allowed;
    }
  }
}
</style>
