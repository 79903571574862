<template>
  <a-week-picker
    :value="date"
    :get-calendar-container="(current) => current.parentNode.parentNode"
    format="YYYY-wo"
    placeholder="请选择周"
    class="select-week"
    @change="onChange"
  >
    <template slot="suffixIcon">
      <a-icon type="down" />
    </template>
  </a-week-picker>
</template>

<script>
//import moment from 'moment';
import SelectDateMixin from '../../mixin/SelectDateMixin';

export default {
  name: 'SelectWeek',
  mixins: [SelectDateMixin],
  data() {
    return {
      type: 'week',
      format: 'YYYY-ww'
    };
  },
};
</script>

<style scoped lang="scss">
@import '../../scss/select-date.scss';
</style>
