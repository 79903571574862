
 <template>
  <div class="bar-item-charts">
    <div ref="chartRef" class="chart" ></div>
  </div>
</template>

<script>
import * as echarts from 'echarts';
import { setDateZoom, saveImage } from '../../../../assets/EChartsConfig';

export default {
  name: 'BarChart',
  props: {
    chartData: { type: Object, default: () => ({}) }
  },
  watch: {
    chartData(data) {
      if (data.datas) {
        this.updateData(data);
      }
    }
  },

  data() {
    return {
      chart: null,
      aveName: '平均值AVE'
    };
  },
  mounted() {
    this.initChart();
  },
  methods: {
    handleClick(evt) {
      this.$emit('x-axis-click', evt);
    },
    initChart() {
      const ref = this.$refs.chartRef;
      if (!ref) {
        return;
      }
      this.chart = echarts.init(ref);
      if (this.chartData.datas) {
        this.updateData(this.chartData);
      }
      this.chart.on('click', 'xAxis', this.handleClick);
    },

    setTitle(option, title) {
      if (title) {
        option.title = {
          text: title,
          top: '10px',
          left: '50%',
          textAlign: 'center',
          textStyle: {
            fontSize: '12px',
            color: ' #999999'
          }
        };
      }
    },
    updateData(data) {
      if (!this.chart) {
        return;
      }
      if (this.chart?.showLoading) {
        this.chart.showLoading();
      }
      const option = {
        xAxis: [
          {
            type: 'category',

            data: data.titles,
            axisTick: {
              show: false
            },
            axisLabel: {
              interval: 0,
              rotate: 65,
              margin: 20,
              textStyle: {
                fontSize: '8px',
                fontFamily: 'PingFangSC-Medium, PingFang SC',
                fontWeight: '500',
                color: ' #999999'
              }
            },
            // 分隔线
            splitLine: {
              show: false
            },
            // 坐标轴轴线
            axisLine: {
              show: false
            }
          }
        ],
        yAxis: [
          {
            splitLine: {
              show: true,
              onZero: true,
              lineStyle: {
                width: 1,
                type: 'dashed',
                color: [
                  {
                    type: 'linear',
                    x: 0,
                    y: 0,
                    x2: 1,
                    y2: 0,
                    colorStops: [
                      {
                        offset: 0,
                        color: '#D4D8E5'
                      },
                      {
                        offset: 1,
                        color: '#D4D8E5' // 100% 处的颜色
                      }
                    ],
                    global: true // 缺省为 false
                  }
                ]
              }
            },
            axisLine: {
              show: true,
              lineStyle: {
                width: 1,
                type: 'dashed',
                color: [
                  {
                    type: 'linear',
                    x: 0,
                    y: 0,
                    x2: 1,
                    y2: 0,
                    colorStops: [
                      {
                        offset: 0,
                        color: '#D4D8E5'
                      },
                      {
                        offset: 1,
                        color: '#D4D8E5' // 100% 处的颜色
                      }
                    ],
                    global: true // 缺省为 false
                  }
                ]
              }
            },
            axisTick: {
              show: false
            },
            /**左侧纵坐标字体设置**/
            axisLabel: {
              textStyle: {
                fontSize: '12px',
                fontFamily: 'PingFangSC-Medium, PingFang SC',
                fontWeight: '500',
                color: ' #999999'
              },
              margin: 20
            } //第一条Y轴
          },

          //第二条Y轴
          {
            position: 'left',
            name: '课程数量',
            //分割线/网格线
            splitLine: {
              show: true,
              lineStyle: {
                width: 1,
                type: 'dashed'
              }
            },
            nameTextStyle: {
              fontSize: 12,
              fontWeight: 500,
              align: 'right',
              width: 10,
              padding: [0, -8, 0, 0]
            }
          },
          {
            type: 'value',
            name: data.title || '销售岗位',
            nameTextStyle: {
              fontSize: 12,
              fontWeight: 600,
              color: '#333333',
              fontFamily: 'PingFangSC-Medium, PingFang SC',
              align: 'right',
              width: 10,
              padding: [0, 100, 0, 0]
            }
          }
        ],
        series: [
          {
            data: data.datas,
            type: 'bar',
            itemStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: data.color?.[0] || '#7EB5DB' },
                  { offset: 1, color: data.color?.[1] || '#7EB5DB' }
                ]),
                label: {
                  show: true, //开启显示
                  position: 'top', //在上方显示
                  textStyle: {
                    //数值样式
                    'font-family': 'DINCondensed-Bold, DINCondensed',
                    color: this.chartData.color?.[0] || '#7EB5DB',
                    fontSize: 8,
                    'font-weight': 'bold',
                    'line-height': '14px'
                  }
                }
              }
            },
            barMaxWidth: 14,
            barHeight: 199
          }
        ],
        tooltip: {
          trigger: 'axis',
          formatter: (params) => {
            if (!params?.length) {
              return;
            }
            const xAxisLabel = params[0].axisValueLabel;
            const items = params
              .map((i) => {
                if (i.seriesName === this.aveName) {
                  return '';
                }
                let color = '';
                if (i.color) {
                  color =
                    typeof i.color !== 'string'
                      ? i.color.colorStops[0].color
                      : i.color;
                }
                return (
                  '<span style="display: inline-block; ' +
                  'width: 12px; ' +
                  'height: 12px; ' +
                  'border-radius: 6px; ' +
                  'margin-right: 10px; ' +
                  'background-color: ' +
                  color +
                  ';"></span>' +
                  '<span style="display: inline-block; width: 100px;">' +
                  this.chartData.title +
                  '</span>' +
                  '<span style="display: inline-block; font-weight: 600;">' +
                  i.value +
                  '</span>' +
                  '<br/>'
                );
              })
              .join('');
            return xAxisLabel + '<br/>' + items;
          },
          axisPointer: {
            type: 'none'
          }
        },
        grid: { left: '50px', bottom: '35%',right:0 },
        dataZoom: []
      };
      setDateZoom(option, true, false, 10, true);
      this.chart.setOption(option, true);
      this.chart.hideLoading();
    },
    showLoading() {
      this.chart.showLoading();
    },
    hideLoading() {
      this.chart.hideLoading();
    },
    saveImage() {
      saveImage(this.chart, this.chartData.title);
    }
  }
};
</script>

<style scoped lang="scss">
.chart {
  width: 100%;
  min-height: 450px;
}
</style>
