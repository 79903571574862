<template>
  <ActiveStatisticsDate
    title="平台活跃统计-月活"
    :data="chartData"
    format="YYYY-MM"
    :loading="loading"
    :allow-clear="false"
    :mode="['month', 'month']"
    :placeholder="['开始月', '结束月']"
    @search="onSearch"
    @reset="onReset"
    @export-excel="onExportExcel"
  />
</template>

<script>
import { getActiveMonth, getActiveMonthDownload } from '../../../../api';

import ActiveStatisticsDate from './ActiveStatisticsDate.vue';
import DateCommon from '@/utils/DateCommon';

export default {
  name: 'ActiveStatisticsMonth',
  components: {
    ActiveStatisticsDate
  },
  data() {
    return {
      loading: false,
      date: undefined,
      chartData: null
    };
  },
  methods: {
    onSearch(evt) {
      this.date = evt?.date || undefined;
      this.getData();
    },
    onReset(evt) {
      this.date = evt?.date || undefined;
      this.getData();
    },
    async getData() {
      if (this.loading) {
        return;
      }
      this.loading = true;
      const params = this.getParams();
      const data = await getActiveMonth(params).finally(() => {
        this.loading = false;
      });
      if (!data || data.error_code) {
        this.$message.error(data?.message || '查询失败');
        return;
      }
      const result = data?.data || {};

      this.chartData = {
        date: data.meta || [],
        series: [result],
        title: result.name || '平台活跃统计-月活',
        color: ['#3D86B9'],
        yAxisName: '用户数（人）',
        yAxisLabelFormatter: '{value}',
        zoomX: true
      };
    },

    async onExportExcel() {
      if (this.loading) {
        return;
      }
      const params = this.getParams();
      this.loading = true;

      const data = await getActiveMonthDownload({
        is_download: 1,
        ...params
      }).finally(() => {
        this.loading = false;
      });
      if (!data || data.error_code) {
        this.$message.error(data?.message || '导出数据失败');
      }
    },
    getParams() {
      return {
        start_day: DateCommon.getMomentStart('month', this.date?.[0]) || '',
        end_day: DateCommon.getMomentStart('month', this.date?.[1]) || ''
      };
    }
  }
};
</script>

<style scoped lang="scss">
</style>

