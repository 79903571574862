import Common from '@/utils/Common';

const baseColor = ['#324FA1', '#324FA1', '#F0C944', '#97D9C1', '#6F68AC'];

function defaultLegend () {
  return {
    show: true,
    type: 'scroll',
    icon: 'rect',
    bottom: '10px',
    itemWidth: 10,
    itemHeight: 10,
  };
}

const lineShadow = {
  width: 4,
  shadowBlur: 5,
  shadowColor: 'rgba(242, 244, 249, 1)',
  shadowOffsetY: 10,
  shadowOffsetX: 0,

};

function getZoomHandler () {
  const x = 9.35;
  const y = 34.56;
  const width = 15;
  const height = 35;

  return {
    handleIcon: `path://M${x},${y}L${x + width},${y}L${x + width},${y + height}L${x},${y + height}z`,
    handleSize: '100%',
    handleStyle: {
      color: '#FFFFFF',
      borderColor: '#97A2FA',
      borderWidth: 1,
      borderType: 'solid',
      borderCap: 'round',
      borderJoin: 'round',
    },
    moveHandleIcon: `path://M${x},${y}L${x + width},${y}L${x + width},${y + height}L${x},${y + height}z`,
    moveHandleSize: '100%',
    moveHandleStyle: {
      color: '#FFFFFF',
      borderColor: '#97A2FA',
      borderWidth: 1,
      borderType: 'solid',
      borderCap: 'round',
      borderJoin: 'round',
    },
  };
}

function setTitle (option, title) {
  if (title) {
    option.title = {
      text: title,
      top: '10px',
      left: '50%',
      textAlign: 'center',
      textStyle: {
        fontSize: '12px',
      },
    };
  }
}

function setZoom (option, zoomX, zoomY) {
  if (option.dataZoom) {
    option.dataZoom = [];
  }
  if (zoomX) {
    option.dataZoom.push(...[
      {
        type: 'slider',
        show: true,
        xAxisIndex: [0],
        bottom: 20,
        start: 0,
        end: 100,
        showDetail: false,
        brushSelect: false,
        dataBackground: {
          areaStyle: {
            color: '#A8BEE6',
          },
        },
        selectedDataBackground: {
          areaStyle: {
            color: '#334FA0',
          },
        },
        ...getZoomHandler(),
      },
      {
        type: 'inside',
        xAxisIndex: [0],
        start: 0,
        end: 10,
      },
    ]);
  }
  if (zoomY) {
    option.dataZoom.push(...[
      {
        type: 'slider',
        show: true,
        yAxisIndex: [0],
        right: 20,
        start: 0,
        end: 10,
      },
      {
        type: 'inside',
        yAxisIndex: [0],
        start: 0,
        end: 10,
      },
    ]);
  }
}

function setDateZoom (option, zoomX, zoomY, showLength, showNone) {
  if (!option.dataZoom) {
    option.dataZoom = [];
  }
  if (zoomX) {
    option.dataZoom.push(...[
      {
        type: 'slider',
        show: showNone || option.xAxis?.[0]?.data?.length > showLength,
        xAxisIndex: [0],
        bottom: 20,
        start: 0,
        end: getEndNum(showLength ? showLength : 4, option.xAxis?.[0]?.data),
        showDetail: false,
        brushSelect: false,
        dataBackground: {
          areaStyle: {
            color: '#A8BEE6',
          },
        },
        selectedDataBackground: {
          areaStyle: {
            color: '#334FA0',
          },
        },
        ...getZoomHandler(),
        guid: {},
      },
      {
        type: 'inside',
        xAxisIndex: [0],
        start: 0,
        end: 10,
      },
    ]);
  }
  if (zoomY) {
    option.dataZoom.push(...[
      {
        type: 'slider',
        show: true,
        yAxisIndex: [0],
        right: 20,
        start: 0,
        end: 10,
      },
      {
        type: 'inside',
        yAxisIndex: [0],
        start: 0,
        end: 10,
      },
    ]);
  }


}

function getEndNum (ShowLength, dataArray) {
  return ShowLength / (dataArray?.length ?? 0) * 100;
}


function saveImage (chart, fileName) {
  if (!chart?.getDataURL) {
    return;
  }

  const pixelRatio = Math.ceil(window.devicePixelRatio) || 1;
  const dataUrl = chart.getDataURL({ type: 'png', pixelRatio });
  Common.downloadFile(dataUrl, fileName + '.png');
}

export {
  defaultLegend,
  lineShadow,
  baseColor,
  setDateZoom,
  getZoomHandler,
  setTitle,
  setZoom,
  saveImage,
};

