<template>
  <div ref="chartRef" class="chart">
  </div>
</template>

<script>
import * as echarts from 'echarts';
import {
  lineShadow,
  baseColor,
  setTitle,
  setDateZoom,
  saveImage,
  defaultLegend,
} from '../../assets/EChartsConfig';

export default {
  name: 'SingleLineChart',
  props: {
    chartData: { type: Object, default: () => ({}) },
  },
  watch: {
    chartData(data) {
      this.updateData(data);
    },
  },
  data() {
    return {
      chart: null,
      isFocus: false,
    };
  },
  mounted() {
    this.initChart();
  },
  methods: {
    initChart() {
      const ref = this.$refs.chartRef;
      if (!ref) {
        return;
      }

      this.chart = echarts.init(ref);
      this.updateData(this.chartData);
    },
    setSeriesItem(option) {
      // eslint-disable-next-line array-callback-return
      option.series.map((i, idx) => {
        i.symbol = 'circle';
        i.symbolSize = 14;
        i.itemStyle = {
          normal: {
            color: option?.color?.[idx],
            borderWidth: 2,
            borderType: 'solid',
            borderColor: '#FFFFFF',
            label: {
              show: true, // 开启显示
              position: 'top', // 在上方显示
              textStyle: {
                // 数值样式
                'font-family': 'DINCondensed-Bold, DINCondensed',
                color: this.chartData.color?.[0] || '#7EB5DB',
                fontSize: 12,
                'font-weight': 'bold',
                'line-height': '14px',
              },
            },
            lineStyle: {
              ...lineShadow,
            },
          },
        };
        i.label = {
          show: true,
          formatter: '{c}',
        };
        i.emphasis = {
          focus: 'series',
          label: {
            show: true,
          },
        };
      });
    },
    updateData(data) {
      if (!this.chart) {
        return;
      }
      if (this.chart?.showLoading) {
        this.chart.showLoading();
      }

      const {
        date = [],
        series = [],
        color = [...baseColor],
        zoomX = true,
        zoomY = false,
        title = '',
        yAxisName = '',
        yAxisLabelFormatter = '{value}',
      } = data || {};

      const option = {
        color,
        tooltip: {
          trigger: 'axis',
          formatter(params) {
            if (!params?.length) {
              return;
            }
            const xAxisLabel = params[0].axisValueLabel;

            const items = params
              .map((i) => (
                `${'<span style="display: inline-block; '
                  + 'width: 12px; '
                  + 'height: 12px; '
                  + 'border-radius: 6px; '
                  + 'margin-right: 10px; '
                  + 'background-color: '}${
                  i.color
                };"></span>`
                  + `<span style="display: inline-block; min-width: 100px;margin-right:10px;">${
                    i.seriesName
                  }</span>`
                  + `<span style="display: inline-block; font-weight: 600;">${
                    i.value
                  }</span>`
                  + '<br/>'
              ))
              .join('');
            // eslint-disable-next-line consistent-return
            return `${xAxisLabel}<br/>${items}`;
          },
          axisPointer: {
            type: 'none',
          },
        },
        grid: {
          left: '60',
          right: '30',
          bottom: '80',
        },
        legend: {
          ...defaultLegend(),
          show: false,
          data: series.map((i) => i.name),
        },
        xAxis: [
          {
            type: 'category',
            boundaryGap: false,
            axisTick: {
              show: false,
              alignWithLabel: true,
            },
            axisLine: {
              show: false,
            },
            axisLabel: {
              normal: {
                textStyle: {
                  fontSize: '12px',
                  fontFamily: ' DINCondensed-Bold, DINCondensed',
                  fontWeight: 'bold',
                  color: ' #999999',
                  'line-height': '14px',
                },
              },
              margin: 20,
            },
            splitLine: {
              show: true,
              onZero: true,
              lineStyle: {
                width: 1,
                type: 'dashed',
                color: [
                  {
                    type: 'linear',
                    x: 0,
                    y: 0,
                    x2: 0,
                    y2: 1,
                    colorStops: [
                      {
                        offset: 0,
                        color: '#FFFFFF', // 0% 处的颜色
                      },
                      {
                        offset: 0.2,
                        color: '#D4D8E5',
                      },
                      {
                        offset: 0.8,
                        color: '#D4D8E5',
                      },
                      {
                        offset: 1,
                        color: '#FFFFFF', // 100% 处的颜色
                      },
                    ],
                    global: false, // 缺省为 false
                  },
                ],
              },
            },
            data: date,
          },
        ],
        yAxis: [
          {
            type: 'value',
            name: yAxisName || '',
            min: 0,
            position: 'left',
            axisLine: {
              show: false,
            },
            axisLabel: {
              normal: {
                formatter: yAxisLabelFormatter,
                textStyle: {
                  fontSize: '12px',
                  fontFamily: 'DINCondensed-Bold, DINCondensed',
                  fontWeight: '500',
                  color: ' #999999',
                },
              },
              margin: 20,
            },
            splitLine: {
              show: false,
            },
          },
        ],
        dataZoom: [],
        series,
      };

      setTitle(option, title);

      this.setSeriesItem(option);
      setDateZoom(option, zoomX, zoomY, 12);
      this.chart.setOption(option, true);
      this.chart.hideLoading();
    },
    saveImage(fileName) {
      saveImage(this.chart, fileName);
    },
    showLoading() {
      this.chart.showLoading();
    },
    hideLoading() {
      this.chart.hideLoading();
    },
  },
};
</script>

<style scoped lang="scss">
.chart {
  width: 900px;
  height: 400px;
}
</style>
