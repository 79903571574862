<template>
  <Card class="monthly-trend">
    <span slot="title">参训率-月度变化趋势</span>
    <div slot="extra">
      <ButtonDownload
        @click="downloadImage('参训率-月度变化趋势')"
        :loading="loading"
      />
      <ButtonExportData @click="exportExcel" :loading="loading" />
    </div>

    <div class="query-1 main-selected-color-wrapper">
      <SelectCourse v-model="courseIds" />
      <SelectShopType
        v-model="orgParams"
        :not-all="true"
        :same-level="true"
      />
      <SelectOrg
        v-model="orgIds"
        :not-all="true"
        :same-level="true"
        class="select-org"
      />
    </div>

    <div class="query-2 main-selected-color-wrapper">
      <SelectRangePicker v-model="date" :allow-clear="false" />
      <ButtonSmall type="primary" @click="onSearch" :loading="loading"
        >查询</ButtonSmall
      >
      <ButtonSmall @click="onReset" :loading="loading">重置</ButtonSmall>
      <ButtonSmall @click="showHiddenModal" :loading="loading"
        >可隐藏项</ButtonSmall
      >
      <span>
        <ButtonSmall
          @click="onShowServiecValue"
          title="数据重叠严重时建议数据隐藏"
          class="small-btn"
          v-if="originData && !originData.showServiecValue"
        >
          <a-icon type="eye" class="icon-datasource" />
          数据显示</ButtonSmall
        >
        <ButtonSmall
          class="small-btn"
          title="数据重叠严重时建议数据隐藏"
          @click="onHideServiecValue"
          v-if="originData && originData.showServiecValue"
        >
          <a-icon type="eye-invisible" class="icon-datasource" />
          数据隐藏</ButtonSmall
        >
      </span>
    </div>

    <MultipleLineChart
      ref="chartRef"
      v-if="originData"
      :chart-data="chartData"
      class="chart"
    />
    <a-empty v-else style="margin: 160px 0"></a-empty>

    <CheckboxModal
      ref="checkboxModalRef"
      v-model="visibleIds"
      title="可隐藏项"
      :options="allVisibleItems"
      @save="visibleSave"
    />
  </Card>
</template>

<script>
import moment from 'moment';

import {
  getParticipationMonthlyTrend,
  getParticipationMonthlyTrendDownload
} from '../../../api';

import ToggleChartVisible from '../../../mixin/ToggleChartVisible';

import Card from '../../layout/Card.vue';
import ButtonDownload from '../../btn/ButtonDownload.vue';
import ButtonExportData from '../../btn/ButtonExportData.vue';
import ButtonSmall from '../../btn/ButtonSmall.vue';
import SelectCourse from '../../selection/SelectCourse.vue';
import SelectShopType from '../../selection/SelectShopType.vue';
import SelectOrg from '../../selection/SelectOrg.vue';
import SelectRangePicker from '../../selection/SelectRangePicker.vue';
import MultipleLineChart from '../../charts/MultipleLineChart.vue';
import CheckboxModal from '../CheckboxModal.vue';
import DateCommon from '@/utils/DateCommon';

export default {
  name: 'MonthlyTrend',
  components: {
    Card,
    ButtonDownload,
    ButtonExportData,
    ButtonSmall,
    SelectCourse,
    SelectShopType,
    SelectOrg,
    SelectRangePicker,
    MultipleLineChart,
    CheckboxModal
  },
  mixins: [ToggleChartVisible],
  watch: {
    orgIds(val) {
      if (val?.split(',')?.length > 10 && !this.noticed) {
        this.noticed = true;
        this.$message.info(
          '当前为数据折线对比图，从合理性考虑建议选择10条以下数据作对比'
        );
      }
    }
  },
  data() {
    return {
      loading: false,
      noticed: false,
      originData: {},
      courseIds: '',
      shopIds: [],
      orgIds: '',
      orgParams: {},
      date: [
        moment().subtract(6, 'months').format('YYYY-MM'),
        moment().subtract(1, 'months').format('YYYY-MM')
      ]
    };
  },
  mounted() {
    this.onReset();
  },
  methods: {
    onSearch() {
      this.getData();
    },
    onReset() {
      this.courseIds = '';
      this.shopIds = [];
      this.orgIds = '';
      this.date = [
        moment().subtract(6, 'months').format('YYYY-MM'),
        moment().subtract(1, 'months').format('YYYY-MM')
      ];

      this.getData();
    },
    async getData() {
      const params = this.getParams();
      if (!params) {
        return;
      }
      this.loading = true;
      this.$refs?.chartRef?.showLoading();

      const data = await getParticipationMonthlyTrend(params).finally(() => {
        this.$refs?.chartRef?.hideLoading();
        this.loading = false;
      });

      if (!data || data.error_code) {
        this.$message.error(data?.message || '查询失败');
        this.originData = undefined;
        return;
      }
      const result = data?.data || {};
      const maxArray = [];
      const minArray = [];
      result.forEach((s) => {
        const min = Math.min(...s.data);
        const max = Math.max(...s.data);
        maxArray.push(max);
        minArray.push(min);
      });
      console.log(
        minArray,
        maxArray,
        Math.min(...minArray),
        Math.max(...maxArray)
      );
      const MatData = {};
      MatData.min = Math.min(...minArray);
      MatData.max = Math.max(...maxArray);
      this.originData = {
        date: data.meta?.abscissa || [],
        series: [...result],
        title: '参训率-月度变化趋势',
        yAxisName: '参训率',
        yAxisLabelFormatter: (value) => {
          return `${parseInt(value)}%`;
        },
        min: MatData.min,
        max: MatData.max,
        showServiecValue: false,
        grid: {
          bottom: 150
        }
      };
      this.visibleIds = this.allVisibleItems.map((i, idx) => idx + 1).join(',');
    },

    async exportExcel() {
      const params = this.getParams();
      if (!params) {
        return;
      }
      this.loading = true;
      const data = await getParticipationMonthlyTrendDownload({
        ...params,
        is_download: 1
      }).finally(() => {
        this.loading = false;
      });
      if (!data || data.error_code) {
        this.$message.error(data?.message || '导出数据失败');
      }
    },
    getParams() {
      if (!(this.date && this.date?.[0])) {
        this.$message.error('请选择时间');
        return false;
      }
      return {
        start_day: DateCommon.getMomentStart('month', this.date?.[0]) || '',
        end_day: DateCommon.getMomentEnd('month', this.date?.[1]) || '',
        course_ids: this.courseIds,
        org_ids: this.orgIds,
        ...this.orgParams
      };
    },
    onblur(data) {
      this.shopIds = data;
    },
    onShowServiecValue() {
      this.originData.showServiecValue = true;
    },
    onHideServiecValue() {
      this.originData.showServiecValue = false;
    }
  }
};
</script>

<style scoped lang="scss">
.monthly-trend {
  width: 610px;
  height: 610px;
}

.query-1 {
  margin-bottom: 10px;
}

.query-2 {
  margin-bottom: 25px;
}

.query-1,
.query-2 {
  & > * {
    margin-left: 0;
    margin-right: 10px;
    vertical-align: middle;

    &:last-child {
      margin-right: 0;
    }
  }
}

.select-org {
  width: 260px;

  margin-right: 0;
}

.chart {
  width: 100%;
}
.small-btn {
  width: 80px;
  .icon-datasource {
    font-size: 14px;
    margin-right: 5px;
  }
}
</style>
