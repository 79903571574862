<template>
  <div class="card-title">
    <div class="line"></div>
    <span class="text"><slot/></span>
  </div>
</template>

<script>
export default {
  name: 'CardTitle',
};
</script>

<style scoped lang="scss">
.card-title {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  //padding: 7px 0 23px 4px;
  padding: 0;

  .line {
    width: 3px;
    height: 15px;
    margin-right: 10px;
    background: #334FA0;
  }

  .text {
    font-size: 14px;
    font-family: PingFangSC-Semibold, PingFang SC, sans-serif;
    font-weight: 600;
    color: #333333;
  }
}
</style>
