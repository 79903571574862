<template>
  <div class="talk-download">
    <div class="left">
      <Card class="mt20">
        <template slot="title"> 话术中心 </template>
        <template>
          <div class="card-view mt-20">
            <TopCard
              v-for="(item, index) in dataSource"
              :key="index"
              :data-source="item"
              :class="item.class"
              @onActive="onActive"
              class="card-view-card"
            >
              <slot>
                <p class="title">
                  {{ item.title }}
                </p>
                <p class="content">
                  <a-tooltip :title="item.oldcontent" placement="rightTop"
                    >{{ item.content
                    }}<span class="content-type">{{
                      item.type
                    }}</span></a-tooltip
                  >
                </p>
              </slot>
            </TopCard>
          </div>
        </template>
      </Card>
    </div>
    <div class="right">
      <Card class="mt20">
        <template slot="title"> 下载中心 </template>
        <template>
          <div class="card-view mt-20">
            <TopCard
              v-for="(item, index) in dataSource2"
              :key="index"
              :data-source="item"
              :class="item.class"
              @onActive="onActive"
              class="card-view-card"
            >
              <slot>
                <p class="title">
                  {{ item.title }}
                </p>
                <p class="content">
                  <a-tooltip :title="item.oldcontent" placement="rightTop">{{
                    item.content
                  }}</a-tooltip>
                </p>
              </slot>
            </TopCard>
          </div>
        </template>
      </Card>
    </div>
  </div>
</template>

<script>
import Card from '../../../layout/Card.vue';
import TopCard from '../TopCard.vue';
import { getDownloadStats, getBasicInfo } from '../../../../api/index';

export default {
  name: 'datacenter-talk-download',
  data() {
    const dataSource = [
      {
        id: 1,
        title: '检核项数',
        content: '0',
        icon: 'user_checked',
        classTitle: '',
        class: 'bg1',
        small: true
      },
      {
        id: 2,
        title: '检核条数',
        content: '0',
        icon: 'user_effective',
        classTitle: '',
        class: 'bg2',
        small: true
      },
      {
        id: 3,
        title: '使用时长',
        content: '0',
        icon: 'pie_quarter',
        classTitle: '人天',
        class: 'bg3',
        navList: [
          {
            id: 1,
            title: '分',
            content: ''
          },
          {
            id: 2,
            title: '时',
            content: ''
          }
        ],
        active: 2,
        small: true
      },
      {
        id: 4,
        title: '使用人数',
        content: '0',
        icon: 'clock',
        classTitle: '分钟',
        class: 'bg4',
        active: 2,
        small: true
      },
      {
        id: 5,
        title: '总使用次数',
        content: '0',
        icon: 'clock',
        classTitle: '分钟',
        class: 'bg5',
        active: 2,
        small: true
      },
      {
        id: 6,
        title: '话术学习使用次数',
        content: '0',
        icon: 'clock',
        classTitle: '分钟',
        class: 'bg6',
        active: 2,
        small: true
      },
      {
        id: 7,
        title: '话术练习使用次数',
        content: '0',
        icon: 'clock',
        classTitle: '分钟',
        class: 'bg7',
        active: 2,
        small: true
      },
      {
        id: 8,
        title: '参与检核使用次数',
        content: '0',
        icon: 'clock',
        classTitle: '分钟',
        class: 'bg8',
        active: 2,
        small: true
      }
    ];
    const dataSource2 = [
      {
        id: 1,
        title: '内容数量',
        content: '0',
        icon: 'user_checked',
        classTitle: '',
        class: 'bg1',
        small: true
      },
      {
        id: 2,
        title: '浏览人数',
        content: '0',
        icon: 'user_effective',
        classTitle: '',
        class: 'bg2',
        small: true
      },
      {
        id: 3,
        title: '分享人数',
        content: '0',
        icon: 'pie_quarter',
        classTitle: '人天',
        class: 'bg3',
        active: 2,
        small: true
      },
      {
        id: 4,
        title: '下载人数',
        content: '0',
        icon: 'clock',
        classTitle: '分钟',
        class: 'bg4',
        active: 2,
        small: true
      }
    ];
    return {
      dataSource,
      dataSource2
    };
  },
  components: {
    Card,
    TopCard,
  },
  methods: {
    async getBasicInfo() {
      const data = await getBasicInfo();
      if (!(data && data.error_code === 0)) {
        return;
      }
      this.dataSource[0].content = data.data.project_num;
      this.dataSource[1].content = data.data.project_row;
      this.dataSource[2].content = data.data.haved_use_time_hours;
      this.dataSource[2].navList[0].content = data.data.haved_use_time_mintes;
      this.dataSource[2].navList[1].content = data.data.haved_use_time_hours;
      this.dataSource[3].content = data.data.haved_user;
      this.dataSource[4].content = data.data.all_use_times;
      this.dataSource[5].content = data.data.skill_study_times;
      this.dataSource[6].content = data.data.skill_practice_times;
      this.dataSource[7].content = data.data.skill_check_times;
      this.setNumLength(this.dataSource);
    },
    async getDownloadStats() {
      const data = await getDownloadStats();
      if (!(data && data.error_code === 0)) {
        return;
      }
      this.dataSource2[0].content = data.data.download_having_num;
      this.dataSource2[1].content = data.data.download_browser_num;
      this.dataSource2[2].content = data.data.download_shared_num;
      this.dataSource2[3].content = data.data.download_haved_down;
      this.setNumLength(this.dataSource2);
    },
    onActive(itemChildren, item) {
      item.active = itemChildren.id;
      item.classTitle = itemChildren.title;
      item.oldcontent = itemChildren.content;
      item.content = this.getContent(itemChildren.content, item);
    },
    init() {
      this.getBasicInfo();
      this.getDownloadStats();
    },
    setNumLength(numList) {
      for (let index = 0; index < numList.length; index++) {
        const element = numList[index];
        element.oldcontent = element.content;
        element.content = this.getContent(element.content);
      }
    },
    getContent(content, item) {
      let result = 0;
      if (item?.type) {
        item.type = '';
      }
      if (content && content.length > 5) {
        result = content.toFixed(0);
      } else {
        result = content;
      }

      if (result && result.toString().length > 5) {
        result = (result / 10000).toFixed(2);
        if (result < 1) {
          result = content.toFixed(0);
          return result;
        }
        item.type = '(万)';
      }
      result = (result / 1)?.toFixed(0);
      return result;
    }
  },
  created() {
    this.init();
  }
};
</script>

<style lang="less" scoped>
.talk-download {
  display: flex;
  .left {
    width: 571px;
    .card-view {
      display: flex;
      align-items: center;
      justify-items: center;
      flex-wrap: wrap;
      justify-content: space-between;
      .card-view-card {
        width: 121px;
        height: 93px;
        margin: 20px 0 0 0;
        padding: 15px 0;

        ::v-deep .data-source-navBar {
          background: #d2dfed;
          border-radius: 8px;
          div {
            font-size: 10px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #334fa0;
          }
          .active {
            background: #3c85b8;
            border-radius: 7px;
            color: #ffffff;
          }
        }
        p {
          padding: 0;
          margin: 0;
          text-align: center;
        }
        .content {
          font-size: 28px;
          font-family: DINCondensed-Bold, DINCondensed;
          font-weight: bold;
          line-height: 34px;
          margin-top: 12px;
        }
        .title {
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          line-height: 17px;
        }
      }
    }
    .bg1 {
      background: #e2e6f2;
      border: 1px solid rgba(41, 74, 165, 0.55);
      p {
        color: #334fa0;
      }
    }
    .bg2 {
      background: #e2e6f2;
      border: 1px solid rgba(41, 74, 165, 0.55);
      p {
        color: #334fa0;
      }
    }
    .bg3 {
      background: #e3eef5;
      border: 1px solid rgba(60, 133, 184, 0.61);
      p {
        color: #3c85b8;
      }
    }
    .bg4 {
      background: #e3eef5;
      border: 1px solid rgba(60, 133, 184, 0.61);
      p {
        color: #3c85b8;
      }
    }
    .bg5 {
      background: #ebf8f3;
      border: 1px solid #95dac1;
      p {
        color: #95dac1;
      }
    }
    .bg6 {
      background: #fdf6e1;
      border: 1px solid #f0c944;
      p {
        color: #f0c944;
      }
    }
    .bg7 {
      background: #fdf6e1;
      border: 1px solid #f0c944;
      p {
        color: #f0c944;
      }
    }
    .bg8 {
      background: #fdf6e1;
      border: 1px solid #f0c944;
      p {
        color: #f0c944;
      }
    }
  }

  .right {
    width: 345px;
    margin-left: 20px;
    .card-view {
      display: flex;
      align-items: center;
      justify-items: center;
      flex-wrap: wrap;
      justify-content: space-between;
      .card-view-card {
        width: 139px;
        height: 93px;
        margin: 20px 0 0 0;
        padding: 15px 0;
        p {
          padding: 0;
          margin: 0;
          text-align: center;
        }
        .content {
          font-size: 28px;
          font-family: DINCondensed-Bold, DINCondensed;
          font-weight: bold;
          line-height: 34px;
          margin-top: 12px;
        }
        .title {
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          line-height: 17px;
        }
      }
    }
    .bg1 {
      background: #ebf8f3;
      border: 1px solid #95dac1;
      p {
        color: #95dac1;
      }
    }
    .bg2 {
      background: #e3eef5;
      border: 1px solid rgba(60, 133, 184, 0.61);
      p {
        color: #3c85b8;
      }
    }
    .bg3 {
      background: #e3eef5;
      border: 1px solid rgba(60, 133, 184, 0.61);
      p {
        color: #3c85b8;
      }
    }
    .bg4 {
      background: #e3eef5;
      border: 1px solid rgba(60, 133, 184, 0.61);
      p {
        color: #3c85b8;
      }
    }
    .bg5 {
      background: #ebf8f3;
      border: 1px solid #95dac1;
      p {
        color: #95dac1;
      }
    }
    .bg6 {
      background: #fdf6e1;
      border: 1px solid #f0c944;
      p {
        color: #f0c944;
      }
    }
    .bg7 {
      background: #fdf6e1;
      border: 1px solid #f0c944;
      p {
        color: #f0c944;
      }
    }
    .bg8 {
      background: #fdf6e1;
      border: 1px solid #f0c944;
      p {
        color: #f0c944;
      }
    }
  }
  .card-view {
    .saerch-list {
      margin-top: 15px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-items: center;
      .search-card {
        margin-right: 10px;
        height: 29px;
        .search-month {
          display: inline-block;
          width: 29px;
          height: 29px;
          line-height: 29px;
          text-align: center;
          background: #ffffff;
          opacity: 0.41;
          border: 1px solid #bccee3;
        }
        .common-btn {
          height: 29px;
          background: #f9fafe;
          border-radius: 4px;
          border: 1px solid #e8e8e8;
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #ffffff;
        }
      }
    }
  }
}
.content-type {
  font-size: 18px;
}
// .mt-20 {
//   margin-top: -20px;
// }
</style>