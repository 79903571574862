import moment from 'moment';

const dayStr = 'YYYY-MM-DD';

const getTypeStr = (dayType) => {
  let result = "";
  switch (dayType) {
    case 'day': result = 'YYYY-M-DD'; break;
    case 'week': result = 'YYYY-ww'; break;
    case 'month': result = 'YYYY-M'; break;
    case 'quarter': result = 'YYYY-Q'; break
    case 'year': result = 'YYYY'; break;
    default: result = ''; break;
  }
  return result;
}

const DateCommon = {
  // getMomentStartTime (dayType, momentDate) {
  //   const str = getTypeStr(dayType);
  //   return momentDate.format(str);
  // },
  // getStringStartTime (dayType, stringDate) {
  //   const str = getTypeStr(dayType);
  //   return moment(stringDate, str).format(str);
  // },
  // getMomentFirstDay (dayType, momentDate) {
  //   return momentDate.startOf('day').format(dayStr);
  // },
  // getStringFirstDay (dayType, stringDate) {
  //   const str = getTypeStr(dayType);
  //   return moment(stringDate, str).startOf('day').format(dayStr);
  // },
  getMomentStart (dayType, stringDate) {
    const str = getTypeStr(dayType);
    return moment(stringDate, str).startOf('day').format(dayStr);
  },
  getMomentEnd (dayType, stringDate) {
    const str = getTypeStr(dayType);
    return moment(stringDate, str).endOf('day').format(dayStr);
  },
  getMoment (dayType, stringDate) {
    const str = getTypeStr(dayType);
    return moment(stringDate, str)
  }
}

export default DateCommon;
