<template>
  <a-date-picker
    :value="date"
    :get-calendar-container="(current) => current.parentNode.parentNode"
    :open="open"
    format="YYYY年"
    :mode="mode"
    :allow-clear="true"
    placeholder="请选择年"
    class="select-year"
    @change="onChange"
    @openChange="openChange"
    @panelChange="panelChange"
  >
    <template slot="suffixIcon">
      <a-icon type="down" />
    </template>
  </a-date-picker>
</template>

<script>
import SelectDateMixin from '../../mixin/SelectDateMixin';
//import moment from 'moment';
export default {
  name: 'SelectYear',
  mixins: [SelectDateMixin],
  data() {
    return {
      type: 'year',
      format: 'YYYY'
    };
  },
  // methods: {
  //   //限制当天之前的日期不可选
  //   disabledDate(current) {
  //     return current && current > moment().subtract(1, 'days').endOf('day');
  //     //return current && current < moment().subtract(0, 'days'); //当天之前的不可选，不包括当天

  //     //return current && current < moment().endOf(‘day');当天之前的不可选，包括当天
  //   }
  // }
};
</script>

<style scoped lang="scss">
@import '../../scss/select-date.scss';
</style>
