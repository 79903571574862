<template>
  <a-button :loading="delay"
            class="btn"
            :class="clazz"
            @click="clicked">
    <slot/>
  </a-button>
</template>

<script>
export default {
  name: 'ButtonSmall',
  props: {
    type: { type: String, default: '' },
    loading: { type: Boolean, default: false },
  },
  computed: {
    clazz() {
      return `${this.type} ${this.loading ? 'loading' : ''}`;
    },
    delay() {
      return this.loading ? { delay: 300 } : false;
    },
  },
  methods: {
    clicked() {
      if (this.loading) {
        return;
      }
      this.$emit('click');
    },
  },
};
</script>

<style scoped lang="scss">
.btn {
  position: relative;
  overflow: hidden;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 31px;
  padding-left: 15px !important;
  padding-right: 15px !important;
  border: 1px solid #E8E8E8;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 400;
  color: #727B84;
  font-family: PingFangSC-Medium, PingFang SC, sans-serif;
  background: #F9FAFE;

  &.primary {
    border: none;
    color: #FFFFFF;
    background: linear-gradient(90deg, #334FA0 0%, #3C85B8 100%);
  }

  &.loading {
    cursor: wait;
    pointer-events: auto !important;
  }

  &:after {
    display: none !important;
  }

  .ant-btn > i, .ant-btn > span {
    transition: none !important;
  }

  ::v-deep {
    .anticon-loading {
      overflow: hidden;
      width: 0;
      height: 0;
      margin: 0 !important;
      padding: 0 !important;
    }

    & > .anticon + span, & > span + .anticon {
      margin-left: 0 !important;
    }
  }
}
</style>
