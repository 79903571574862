<template>
  <div v-show="alwaysShow || shown" @click="scrollToTop">
    <slot/>
  </div>
</template>

<script>
export default {
  name: 'BackToTop',
  props: ['alwaysShow'],
  mounted() {
    this.listen();
  },
  beforeDestroy() {
    this.removeListen();
  },
  data() {
    return {
      shown: false,
      el: null,
    };
  },
  methods: {
    listen() {
      this.el = document.getElementById('appIndexContent');
      this.el.addEventListener('scroll', this.scrolling, false);
    },
    removeListen() {
      this.el = document.getElementById('appIndexContent');
      this.el.removeEventListener('scroll', this.scrolling);
    },

    scrolling() {
      // this.shown = this.el.scrollTop > 1.5 * this.el.clientHeight;
      this.shown = this.el.scrollTop > window.innerHeight / 2;
    },
    scrollToTop() {
      this.el.scrollTo(0, 0);
      this.$emit('scroll-to-top');
    },
  },
};
</script>
