<template>
  <a-modal
    :title="title"
    :visible="shown"
    :confirm-loading="loading"
    :mask-closable="true"
    :width="500"
    :centered="true"
    wrap-class-name="data-center-popup-modal"
    @cancel="close">
    <a-checkbox-group v-model="checkedKeys" class="checkbox-group">
      <template v-for="item of options">
        <a-checkbox :key="item.id" :value="item.id" class="checkbox">{{
            item.name
          }}
        </a-checkbox>
      </template>
    </a-checkbox-group>

    <div slot="footer" class="footer">
      <a-button class="btn cancel" @click="close">取消</a-button>
      <a-button class="btn confirm" @click="save">确认</a-button>
    </div>
  </a-modal>
</template>

<script>
export default {
  name: 'CheckboxModal',
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    value: { type: String, default: '' },
    title: { type: String, default: '弹窗' },
    options: { type: Array, default: () => [] },
  },
  watch: {
    value: {
      immediate: true,
      handler(val) {
        this.checkedKeys = val.split(',').map((i) => +i).filter((i) => !!i);
      },
    },
    checkedKeys: {
      immediate: true,
      handler(val, old) {
        if (val?.length === 0 && old?.length) {
          this.$message.info('请最少选择一条数据');
          this.checkedKeys = old;
          return;
        }
        const copyVal = [...(val || [])].sort();
        const copyOld = [...(old || [])].sort();
        if (
          copyVal &&
          copyVal.length === copyOld?.length &&
          copyVal.every((i, idx) => i === copyOld[idx])
        ) {
          return;
        }
        this.$emit('change', (this.checkedKeys || []).join(','));
        this.$emit(
          'changed',
          this.options.filter((i) => this.checkedKeys.includes(i)).map((i) => ({ ...i })),
        );
      },
    },
  },
  data() {
    return {
      shown: false,
      loading: false,
      checkedKeys: [],
      oldCheckedKeys: undefined,
    };
  },
  methods: {
    show() {
      this.shown = true;
      this.checkedKeys = this.value.split(',').map((i) => +i).filter((i) => !!i);
    },
    close() {
      this.shown = false;
    },
    save() {
      if (this.checkedKeys.length === 0) {
        this.$message.info('至少选择一项');
        return;
      }
      this.$emit('save', this.checkedKeys.join(','));
      this.$emit(
        'saved',
        this.options.filter(
          (i) => this.checkedKeys.includes(i.id),
        ).map((i) => ({ ...i })),
      );
      this.close();
    },
  },
};
</script>

<style scoped lang="scss">
.checkbox-group {
  width: 100%;
  max-height: 50vh;
  overflow-y: auto;

  ::v-deep {
    .ant-checkbox-wrapper {
      margin: 6px 10px;
    }

    .ant-checkbox-checked .ant-checkbox-inner {
      border-color: #334fa0;
      background-color: #334fa0;
    }
  }
}

.checkbox {
  width: calc(33% - 20px);
}
</style>

<style lang="scss">
@import '../../scss/modal.scss';

.data-center-popup-modal {
  .ant-modal-body {
    min-height: 0;
    padding: 20px;
  }
}
</style>
