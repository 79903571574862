<template>
  <div ref="chartRef" class="chart"></div>
</template>

<script>
import * as echarts from 'echarts';
import {
  lineShadow,
  setDateZoom,
  setTitle,
  saveImage,
  defaultLegend,
} from '../../assets/EChartsConfig';

export default {
  name: 'MultipleLineChart',
  props: {
    chartData: { type: Object, default: () => ({}) },
  },
  watch: {
    chartData(data) {
      this.updateData(data);
    },
  },
  data() {
    return {
      chart: null,
      isFocus: false,
      showLabelIndex: 0,
      seriesName: '',
    };
  },
  mounted() {
    this.initChart();
  },
  methods: {
    handleMove(params) {
      if (params.componentType === 'xAxis') {
        const elementDiv = document.querySelector('.extension');
        // 设置悬浮文本的位置以及样式
        const elementStyle = 'position: absolute;z-index: 99999;color: #fff;font-size: 12px;padding: 5px;display: inline;border-radius: 4px;background-color: #303133;box-shadow: rgba(0, 0, 0, 0.3) 2px 2px 8px';
        elementDiv.style.cssText = elementStyle;
        elementDiv.innerHTML = params.value;
        document.querySelector('html').onmousemove = function (event) {
          // eslint-disable-next-line no-shadow
          const elementDiv = document.querySelector('.extension');
          const xx = event.pageX - 10;
          const yy = event.pageY + 15;

          elementDiv.style.top = `${yy}px`;
          elementDiv.style.left = `${xx}px`;
        };
      }
    },
    handleOut(params) {
      // 注意这里，我是以X轴显示内容过长为例，如果是y轴的话，需要改为yAxis
      if (params.componentType === 'xAxis') {
        const elementDiv = document.querySelector('.extension');

        elementDiv.style.cssText = 'display:none';
      }
    },
    initChart() {
      const ref = this.$refs.chartRef;
      if (!ref) {
        return;
      }
      this.chart = echarts.init(ref);
      this.updateData(this.chartData);
      this.chart.on('mousemove', 'xAxis', this.handleMove);
      this.chart.on('mouseout', 'xAxis', this.handleOut);
      this.chart.on('mousemove', 'series.line', this.showLabel);
      this.chart.getZr().on('mouseout', 'series.line', this.hideLabel);
    },
    setSeriesItem(option, showLabelIndex) {
      // eslint-disable-next-line array-callback-return
      option.series.map((i, idx) => {
        i.symbol = 'circle';
        i.symbolSize = 8;
        i.itemStyle = {
          normal: {
            color: option?.color?.[idx],
            borderWidth: 2,
            borderType: 'solid',
            borderColor: '#FFFFFF',
            label: {
              show: this.chartData.showServiecValue, // 开启显示
              position: 'right', // 在上方显示
              textStyle: {
                // 数值样式
                'font-family': 'DINCondensed-Bold, DINCondensed',
                color: i.color?.[0] || '#7EB5DB',
                fontSize: 8,
                'font-weight': '800',
                'line-height': '14px',
                backgroundColor: '#fff',
              },
              formatter: '{c}%',
            },
            lineStyle: {
              ...lineShadow,
            },
          },
        };
        i.triggerLineEvent = true;
        i.label = {
          show: idx === showLabelIndex,
          formatter: '{c}',
        };
        i.emphasis = {
          focus: 'series.line',
          label: {
            show: true,
          },
        };
        if (
          (showLabelIndex || showLabelIndex === 0)
          && idx !== showLabelIndex
        ) {
          i.symbolSize = 10;
          i.itemStyle.color = echarts.color.modifyAlpha(
            option?.color?.[idx],
            0.3,
          );
        }
      });
    },

    updateData(data, showLabelIndex) {
      if (!this.chart) {
        return;
      }
      if (this.chart?.showLoading) {
        this.chart.showLoading();
      }

      const {
        date = [],
        series = [],
        color = [],
        zoomX = true,
        zoomY = false,
        title = '',
        yAxisName = '',
        min = 0,
        max = 1,
        yAxisLabelFormatter = '{value}%',
      } = data || {};

      const option = {
        color,
        tooltip: {
          trigger: 'axis',
          formatter(params) {
            if (!params?.length) {
              return;
            }
            const xAxisLabel = params[0].axisValueLabel;

            const items = params
              .map((i) => (
                `${'<span style="display: inline-block; '
                  + 'width: 12px; '
                  + 'height: 12px; '
                  + 'border-radius: 6px; '
                  + 'margin-right: 10px; '
                  + 'background-color: '}${
                  i.color
                };"></span>`
                  + `<span style="display: inline-block; min-width: 100px;margin-right:10px;">${
                    i.seriesName
                  }</span>`
                  + `<span style="display: inline-block; font-weight: 600;">${
                    i.value
                  }%</span>`
                  + '<br/>'
              ))
              .join('');
            // eslint-disable-next-line consistent-return
            return `${xAxisLabel}<br/>${items}`;
          },
          axisPointer: {
            type: 'none',
          },
        },
        grid: this.chartData.grid || {
          left: '50',
          right: '30',
          bottom: '80',
        },
        legend: {
          ...defaultLegend(),
          show: true,
          padding: [0, 0, 70, 0],
          data: series.map((i) => i.name),
        },
        xAxis: [
          {
            type: 'category',
            boundaryGap: false,
            triggerEvent: true,
            axisTick: {
              show: false,
              alignWithLabel: true,
            },
            axisLine: {
              show: false,
            },
            splitLine: {
              show: true,
              onZero: true,
              lineStyle: {
                width: 1,
                type: 'dashed',
                color: [
                  {
                    type: 'linear',
                    x: 0,
                    y: 0,
                    x2: 0,
                    y2: 1,
                    colorStops: [
                      {
                        offset: 0,
                        color: '#FFFFFF', // 0% 处的颜色
                      },
                      {
                        offset: 0.5,
                        color: '#D4D8E5',
                      },
                      {
                        offset: 1,
                        color: '#FFFFFF', // 100% 处的颜色
                      },
                    ],
                    global: false, // 缺省为 false
                  },
                ],
              },
            },
            axisLabel: {
              margin: 20,
            },
            data: date,
            padding: [80],
          },
        ],
        yAxis: [
          {
            type: 'value',
            name: yAxisName || '',
            scale: true,
            position: 'left',
            axisLine: {
              show: false,
            },
            min,
            max,
            interval: (max - min) / 5,
            axisLabel: {
              formatter: yAxisLabelFormatter,
              margin: 20,
            },
            splitLine: {
              show: false,
            },
            padding: [0],
          },
        ],
        dataZoom: [],
        series,
      };

      setTitle(option, title);
      this.setSeriesItem(option, showLabelIndex);

      setDateZoom(option, zoomX, zoomY, 100, true);
      this.chart.setOption(option, true);
      this.chart.hideLoading();
    },

    showLabel(evt) {
      if (evt.componentType !== 'series') {
        return;
      }
      this.chart.dispatchAction({
        type: 'highlight',
        seriesIndex: evt.seriesIndex,
        dataIndex: evt.currentIndex,
      });
      // evt.pointerEvents = 'none';
      // if (!this.isFocus) {
      //   this.isFocus = true;
      //   this.showLabelIndex = evt.seriesIndex;
      //   this.seriesName = evt.name;
      //   console.log('showLabel', evt, this.showLabelIndex, this.seriesName);
      //   this.updateData(this.chartData, evt.seriesIndex, false);
      // }
    },
    hideLabel(evt) {
      evt.pointerEvents = null;
      this.chart.dispatchAction({
        type: 'downplay',
        seriesIndex: evt.seriesIndex,
        dataIndex: evt.currentIndex,
      });
    },
    saveImage(fileName) {
      saveImage(this.chart, fileName);
    },
    showLoading() {
      this.chart.showLoading();
    },
    hideLoading() {
      this.chart.hideLoading();
    },
  },
};
</script>

<style scoped lang="scss">
.chart {
  width: 500px;
  height: 430px;
}
</style>
