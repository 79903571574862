<template>
  <Card class="participation-overview">
    <span slot="title">参训率总览</span>

    <div class="selection-wrapper">
      <SelectTypedDate v-model="typedDate" />
    </div>

    <div class="btn-wrapper">
      <ButtonSmall type="primary" @click="onSearch" :loading="loading"
        >查询</ButtonSmall
      >
      <ButtonSmall @click="onReset" :loading="loading">重置</ButtonSmall>
    </div>
    <div v-if="chartData">
      <div class="flex">
        <TwoSegmentsCircleChart ref="chatrs" :chart-data="chartData" />
      </div>
      <div v-if="typeNumber">
        <div class="increase-rate-wrapper">
          <span class="text">{{ typeNumber }}%</span>
          <img
            :src="increaseIcon"
            alt=""
            class="increase-icon"
            :class="{ decrease: !isIncrease }"
          />
        </div>
        <div class="increase-text">
          <span v-if="isIncrease">与{{ typeName }}环比增长</span>
          <span v-else>与{{ typeName }}环比降低</span>
        </div>
      </div>
    </div>
    <template v-else>
      <a-empty />
    </template>
  </Card>
</template>

<script>
import moment from 'moment';
import { ossBaseUrl } from '@/Config';

import Card from '../../layout/Card.vue';
import SelectTypedDate from '../../selection/SelectTypedDate.vue';
import ButtonSmall from '../../btn/ButtonSmall.vue';
import TwoSegmentsCircleChart from '../../charts/TwoSegmentsCircleChart.vue';
import { getPieChart } from '../../../api/index';
import DateCommon from '@/utils/DateCommon';

export default {
  name: 'SectionOverview',
  components: {
    Card,
    SelectTypedDate,
    ButtonSmall,
    TwoSegmentsCircleChart
  },
  computed: {
    increaseIcon() {
      return `${ossBaseUrl}dataCenter/increase_icon.png`;
    }
  },
  data() {
    return {
      typedDate: {
        type: 'month',
        date: moment().subtract(1, 'months')
      },
      isIncrease: true,
      chartData: {},
      type: 1,
      typeNumber: undefined,
      typeName: '',
      loading: false
    };
  },
  mounted() {
    this.onReset();
  },
  methods: {
    onSearch() {
      this.getData();
    },
    async onReset() {
      this.typedDate = {
        type: 'month',
        date: moment().subtract(1, 'months').format('YYYY-MM')
      };
      await this.getData();
    },
    async getData() {
      const params = this.getSearchParams();
      if (!params) {
        return;
      }
      this.chartData = {};
      setTimeout(async () => {
        await this.getPieChart(params);
      });
    },
    async getPieChart(params) {
      this.$refs?.chatrs?.showLoading();
      this.loading = true;
      const data = await getPieChart(params).finally(() => {
        this.$refs?.chatrs?.hideLoading();
      });
      this.loading = false;
      if (!(data && data.error_code === 0)) {
        this.chartData = undefined;
        return;
      }
      if (data?.data?.join_rate === 0 && data?.data?.not_join_rate === 0) {
        this.chartData = undefined;
        return;
      }
      this.chartData = {
        series: [
          {
            name: '已参训',
            data: data.data.join_rate
          },
          {
            name: '未参训',
            data: data.data.not_join_rate
          }
        ]
      };
      this.typeNumber = data.data.compare_last_month;
      const type = data.data.compare_last_month_icon;
      this.isIncrease = type === 1;
    },
    getSearchParams() {
      if (!this.typedDate.date) {
        this.$message.error('请选择时间');
        return;
      }
      switch (this.typedDate.type) {
        case 'month':
          this.typeName = '上月';
          break;
        case 'quarter':
          this.typeName = '上个季度';
          break;
        case 'week':
          this.typeName = '上周';
          break;
        case 'year':
          this.typeName = '去年';
          break;
        default:
          this.typeName = '上月';
          break;
      }

      return {
        day_type: this.typedDate.type,
        start_day: DateCommon.getMomentStart(
          this.typedDate.type,
          this.typedDate.date
        )
      };
    }
  }
};
</script>

<style scoped lang="scss">
.participation-overview {
  width: 307px;
  height: 610px;
}

.selection-wrapper {
  margin-bottom: 10px;
}

.btn-wrapper {
  margin-bottom: 10px;
}

.increase-rate-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 28px;
  font-family: DINCondensed-Bold, DINCondensed, sans-serif;
  font-weight: bold;
  color: #334fa0;

  .text {
    margin-right: 7px;
  }

  .increase-icon {
    width: 14px;
    height: 15px;
  }
  .decrease {
    transform: rotate(180deg);
  }
}

.increase-text {
  font-size: 12px;
  text-align: center;
  font-family: PingFangSC-Regular, PingFang SC, sans-serif;
  font-weight: 400;
  color: #334fa0;
}
::v-deep .ant-empty {
  margin: 50% 0;
}
</style>
